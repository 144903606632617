<script setup>
import { ref, onMounted, watch } from "vue";
import { useUserStore } from "../../../stores/user";
import c3api from "../../../c3api";
import { useRouter, useRoute } from "vue-router/auto";
import FabButton from "../../../components/FabButton.vue";
import pageTitle, { setPageTitle } from "../../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../../utils/dateUtils";
import { useLoadingStore } from "../../../stores/loading";

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
let changelog = ref(null);
const page = ref(parseInt(route.query.page) || 1);
const filters = ref(null);
const searchText = ref(null);
const sortBy = ref("id");
const loading = useLoadingStore();

onMounted(() => {
  getChangelog();
});

watch(page, () => {
  getChangelog();
});

const getChangelog = async () => {
  loading.state(true);
  c3api
    .get(`/changelogs/${route.params.id}}`)
    .then((response) => {
      changelog.value = response.data.data;
      setPageTitle(changelog.value.title);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.state(false);
    });
};

function updatePage(newPage) {
  router.push({ query: { page: newPage } });
}

watch(searchText, () => {
  if (searchText.value.length > 0) {
    page.value = 1;
    filters.value = [
      {
        column: "name",
        condition: "like",
        value: searchText.value,
      },
    ];
  } else {
    filters.value = null;
  }
  getChangelog();
});
</script>

<template>
  <div v-if="userStore.user && changelog">
    <v-card-title>{{ changelog.title }}</v-card-title>
    <v-card-subtitle
      >Created by {{ changelog.created_by.name }} on
      {{ shortDateWithTimeTz(changelog.created_at) }}.
    </v-card-subtitle>
    <v-card flat class="mx-4 px-4 mt-4">
      <div class="raw-body pa-4" v-html="changelog.raw_body"></div>
    </v-card>
    <div class="text-center mt-4" v-if="changelog && changelog.total_pages > 1">
      <v-pagination
        v-model="page"
        :length="changelog.total_pages"
        @update:modelValue="updatePage"
        rounded="circle"
      ></v-pagination>
    </div>
  </div>
  <FabButton
    :to="`/changelogs/${route.params.id}/edit`"
    prepend-icon="mdi-pencil"
    label="Edit"
  />
</template>

<style>
div.raw-body p {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.raw-body h1,
div.raw-body h2,
div.raw-body h3,
div.raw-body h4,
div.raw-body h5,
div.raw-body h6 {
  margin-top: 20px;
  margin-bottom: 15px;
}

div.raw-body h1 {
  font-size: 2em; /* Optional: Adjust size of each heading level if desired */
}

div.raw-body h2 {
  font-size: 1.75em;
}

div.raw-body h3 {
  font-size: 1.5em;
}

/* ... Continue for h4, h5, and h6 if desired */
</style>
